import { Box, Text, useToast, MultiSearchSelector, useTranslation, Button, Option, Spinner } from "@familyzone/component-library";
import React, { useEffect } from "react";
import CardBasedPage from "../../components/templates/CardBasedPage";
import { getAllSchools } from "../../utils/api/Schools";
import { getExcludedSchools, updateExcludedSchools } from "../../utils/api/SchoolAssociations";
import { School } from "../../types/Schools";
import { schoolLabelFormatter } from "../../components/SchoolSearch/SchoolSearchHelper";

const SchoolAssociations: React.FC = () => {
  const { t } = useTranslation();
  const { successToast, errorToast } = useToast();
  const title = t("School Associations");
  const breadcrumbs = [
    { title: t("Configuration"), url: "/config", isActive: false },
    { title: t("Classwize"), url: "/config/device/classwize", isActive: false },
    { title: title, isActive: true },
  ];

  const [allSchools, setAllSchools] = React.useState<Option[]>([]);
  const [selectedSchools, setSelectedSchools] = React.useState<Option[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [saving, setSaving] = React.useState(false);

  useEffect(() => {
    const fetchSchools = async () => {
      try {
        const allSchools = await getAllSchools();
        const schoolOptions: Option[] = allSchools.map((school) => ({
          label: schoolLabelFormatter(school.name, school.description),
          value: school.stableIDv1,
        }));
        setAllSchools(schoolOptions);

        // Excluded schools are only stored as stableIDs, so we need to map them to the full school objects
        const schoolsMap = new Map<string, School>();
        for (const school of allSchools) {
          schoolsMap.set(school.stableIDv1, school);
        }

        const excludedSchoolStableIDv1s = await getExcludedSchools();
        const selectedSchools: (Option | null)[] = excludedSchoolStableIDv1s.map((stableId) => {
          const school = schoolsMap.get(stableId);
          // If a school is undefined here it means it has been deleted by sync, so it's no longer a valid option and we ignore it.
          if (school) {
            return {
              label: schoolLabelFormatter(school.name, school.description),
              value: school.stableIDv1,
            };
          }
          return null; // Explicitly return null for unmatched cases
        });
        setSelectedSchools(selectedSchools.filter((option) => option !== null) as Option[]);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch schools:", error);
      }
    };

    void fetchSchools();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CardBasedPage title={title} breadcrumbs={breadcrumbs}>
      <Box p="sp24">
        <Box borderRadius="sm" p="sp12" backgroundColor="neutrals.20" mb={30} data-testid="info-text">
          <Text fontFamily="Poppins" fontSize="sm" color="text.paragraph.regular" role="heading">
            Exclude schools from the School Associations feature. Teachers in these schools will be able to see all students in all schools.
          </Text>
        </Box>
        {loading ? (
          <Spinner data-testid="loading-spinner" />
        ) : (
          <>
            <Box width={240}>
              <Text color="text.paragraph.regular">Exclusions</Text>
            </Box>
            <Box flexGrow={1} data-testid="school-selector">
              <MultiSearchSelector
                disabled={loading || saving}
                placeholder={t("Select schools to exclude")}
                options={allSchools}
                selected={selectedSchools}
                onChange={(selected: Option[]) => {
                  setSelectedSchools(selected);
                }}
                clearInputOnSelect
                filterOptions={true}
              />
            </Box>
          </>
        )}
        <Box pt="sp24" overflow={"hidden"}>
          <Button
            variant="primary"
            data-testid="save-button"
            onClick={() => {
              void (async () => {
                try {
                  setSaving(true);
                  const excludedSchoolStableIDv1s: string[] = selectedSchools.map((option) => option.value as string);
                  await updateExcludedSchools(excludedSchoolStableIDv1s);
                  setSaving(false);
                  successToast({
                    title: t("Success"),
                    description: t("School exclusions have been saved successfully."),
                    position: "top",
                    isClosable: true,
                  });
                } catch (error) {
                  setSaving(false);
                  errorToast({
                    title: t("Error"),
                    description: t("Failed to save school exclusions. Please try again."),
                    position: "top",
                    isClosable: true,
                  });
                }
              })();
            }}
            disabled={loading || saving}
          >
            {saving ? t("Saving...") : t("Save")}
          </Button>
        </Box>
      </Box>
    </CardBasedPage>
  );
};

export default SchoolAssociations;
