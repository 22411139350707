import { headers } from "./ApiHelpers";

const baseUrl = "/api/schoolassociations";

export const getExcludedSchools = async (): Promise<string[]> => {
  const raw = await fetch(`${baseUrl}/exclusions`, {
    headers: headers(),
  });
  const response: unknown = await raw.json();

  if (responseHasStableIDv1Array(response) && isStringArray(response.schoolStableIDv1s)) {
    return response.schoolStableIDv1s;
  } else {
    throw new Error("Invalid response from server");
  }
};

const responseHasStableIDv1Array = (response: unknown): response is { schoolStableIDv1s: unknown[] } => {
  return response !== null && typeof response === "object" && "schoolStableIDv1s" in response && Array.isArray(response.schoolStableIDv1s);
};

function isStringArray(response: unknown): response is string[] {
  return Array.isArray(response) && response.every((item) => typeof item === "string");
}

export const updateExcludedSchools = async (excludedSchools: string[]): Promise<void> => {
  const raw = await fetch(`${baseUrl}/exclusions`, {
    method: "PUT",
    headers: headers(),
    body: JSON.stringify({ schoolStableIDv1s: excludedSchools }),
  });

  if (!raw.ok) {
    throw new Error("Failed to update excluded schools");
  }
};
