import { School, SearchSchoolsResponse } from "../../types/Schools";
import { headers } from "./ApiHelpers";

const baseUrl = "/api/schools";

export const searchSchools = async (query: string): Promise<School[]> => {
  const raw = await fetch(`${baseUrl}/search?query=${encodeURIComponent(query)}`, {
    headers: headers(),
  });
  const response: unknown = await raw.json();

  if (isSchoolSearchResult(response) && isSchoolObjects(response.schools)) {
    return response.schools;
  } else {
    throw new Error("Invalid response from server");
  }
};

const isSchoolSearchResult = (response: unknown): response is SearchSchoolsResponse => responseHasSchoolsArray(response);

const isSchoolObjects = (schools: unknown[]): schools is School[] => {
  if (schools.length === 0) return true;
  return schools.some((school) => isSchool(school));
};

const isSchool = (school: unknown): school is School => {
  return school !== null && typeof school === "object" && "id" in school;
};

const responseHasSchoolsArray = (response: unknown): response is { schools: unknown[] } => {
  return response !== null && typeof response === "object" && "schools" in response && Array.isArray(response.schools);
};

export const getAllSchools = async (): Promise<School[]> => {
  const raw = await fetch(`${baseUrl}/all`, {
    headers: headers(),
  });
  const response: unknown = await raw.json();

  if (responseHasSchoolsArray(response) && isSchoolObjects(response.schools)) {
    return response.schools;
  } else {
    throw new Error("Invalid response from server");
  }
};
